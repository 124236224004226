export default {
  //公共搜索
  name: "drawer",
  data() {
    return {
      option: {
        backgroundColor: 'rgba(220,38,38,0)',
        color: ['#fff', '#fec42c', '#80F1BE'],
        grid: {
          left: 30,
          top: 30,
          right: 30, 
          bottom: 0
        },
        xAxis: {
          show: false,
          type: 'value',
          name: '日期',
          nameGap: 16,
        },
        yAxis: {
          show: false,
          type: 'value',
          name: 'AQI指数',
          nameLocation: 'end',
          nameGap: 20,
        },
        series: [
          {
            name: '上海',
            type: 'scatter',
            itemStyle: {
              normal: {
                opacity: 1,
                shadowBlur: 2,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [
              [1, 91, 45, 125, 0.82, 34, 23, "良"],
              [2, 65, 27, 78, 0.86, 45, 29, "良"],
              [3, 83, 60, 84, 1.09, 73, 27, "良"],
              [4, 109, 81, 121, 1.28, 68, 51, "轻度污染"],
              [5, 106, 77, 114, 1.07, 55, 51, "轻度污染"],
              [6, 109, 81, 121, 1.28, 68, 51, "轻度污染"],
              [7, 106, 77, 114, 1.07, 55, 51, "轻度污染"],
              [8, 89, 65, 78, 0.86, 51, 26, "良"],
              [9, 53, 33, 47, 0.64, 50, 17, "良"],
              [10, 80, 55, 80, 1.01, 75, 24, "良"],
              [11, 117, 81, 124, 1.03, 45, 24, "轻度污染"],
              [12, 99, 71, 142, 1.1, 62, 42, "良"],
              [13, 95, 69, 130, 1.28, 74, 50, "良"],
              [14, 116, 87, 131, 1.47, 84, 40, "轻度污染"],
              [15, 108, 80, 121, 1.3, 85, 37, "轻度污染"],
              [16, 134, 83, 167, 1.16, 57, 43, "轻度污染"],
              [17, 79, 43, 107, 1.05, 59, 37, "良"],
              [18, 71, 46, 89, 0.86, 64, 25, "良"],
              [19, 97, 71, 113, 1.17, 88, 31, "良"],
              [20, 84, 57, 91, 0.85, 55, 31, "良"],
              [21, 87, 63, 101, 0.9, 56, 41, "良"],
              [22, 104, 77, 119, 1.09, 73, 48, "轻度污染"],
              [23, 87, 62, 100, 1, 72, 28, "良"],
              [24, 168, 128, 172, 1.49, 97, 56, "中度污染"],
              [25, 65, 45, 51, 0.74, 39, 17, "良"],
              [26, 39, 24, 38, 0.61, 47, 17, "优"],
              [27, 39, 24, 39, 0.59, 50, 19, "优"],
              [28, 93, 68, 96, 1.05, 79, 29, "良"],
              [29, 188, 143, 197, 1.66, 99, 51, "中度污染"],
              [30, 174, 131, 174, 1.55, 108, 50, "中度污染"],
              [31, 187, 143, 201, 1.39, 89, 53, "中度污染"]
            ]
          },
          {
            name: '广州',
            type: 'scatter',
            itemStyle: {
              normal: {
                opacity: 1,
                shadowBlur: 2,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [
              [1, 26, 37, 27, 1.163, 27, 13, "优"],
              [2, 85, 62, 71, 1.195, 60, 8, "良"],
              [3, 78, 38, 74, 1.363, 37, 7, "良"],
              [4, 21, 21, 36, 0.634, 40, 9, "优"],
              [5, 41, 42, 46, 0.915, 81, 13, "优"],
              [6, 56, 52, 69, 1.067, 92, 16, "良"],
              [7, 64, 30, 28, 0.924, 51, 2, "良"],
              [8, 55, 48, 74, 1.236, 75, 26, "良"],
              [9, 76, 85, 113, 1.237, 114, 27, "良"],
              [10, 91, 81, 104, 1.041, 56, 40, "良"],
              [11, 84, 39, 60, 0.964, 25, 11, "良"],
              [12, 64, 51, 101, 0.862, 58, 23, "良"],
              [13, 70, 69, 120, 1.198, 65, 36, "良"],
              [14, 77, 105, 178, 2.549, 64, 16, "良"],
              [15, 109, 68, 87, 0.996, 74, 29, "轻度污染"],
              [16, 73, 68, 97, 0.905, 51, 34, "良"],
              [17, 54, 27, 47, 0.592, 53, 12, "良"],
              [18, 51, 61, 97, 0.811, 65, 19, "良"],
              [19, 91, 71, 121, 1.374, 43, 18, "良"],
              [20, 73, 102, 182, 2.787, 44, 19, "良"],
              [21, 73, 50, 76, 0.717, 31, 20, "良"],
              [22, 84, 94, 140, 2.238, 68, 18, "良"],
              [23, 93, 77, 104, 1.165, 53, 7, "良"],
              [24, 99, 130, 227, 3.97, 55, 15, "良"],
              [25, 146, 84, 139, 1.094, 40, 17, "轻度污染"],
              [26, 113, 108, 137, 1.481, 48, 15, "轻度污染"],
              [27, 81, 48, 62, 1.619, 26, 3, "良"],
              [28, 56, 48, 68, 1.336, 37, 9, "良"],
              [29, 82, 92, 174, 3.29, 0, 13, "良"],
              [30, 106, 116, 188, 3.628, 101, 16, "轻度污染"],
              [31, 118, 50, 0, 1.383, 76, 11, "轻度污染"]
            ]
          }
        ]
      }
    }
  },
  methods: {
    onClose() {
      var myChart = this.$echarts.init(document.getElementById('dotImg'));
      myChart.setOption(this.option);
      this.resize(myChart);
    },
    resize(myChart){
      window.addEventListener('resize', e => { 
        myChart.resize();
      });
    }
  },
  mounted() {
    this.onClose();
  }
};
