export const rformat = {
    methods: {
        //科目
        r_projectItem(value) {
            switch (value) {
                case 2:
                    return '科目2';
                    break;
                case 3:
                    return '科目3';
                    break;
                default:
                    return '未知';
            }
        },
        r_projectItemy(value) {
            switch (value) {
                case 2:
                    return '科目2';
                    break;
                case 3:
                    return '科目3';
                    break;
                default:
                    return '未知';
            }
        },
        //
        //车型
        r_driverCarType(value) {
            switch (value) {
                case 'A1':
                    return 'A1';
                    break;
                case 'A2':
                    return 'A2';
                    break;
                case 'A3':
                    return 'A3';
                    break;
                case 'B1':
                    return 'B1';
                    break;
                case 'B2':
                    return 'B2';
                    break;
                case 'C1':
                    return 'C1';
                    break;
                case 'C2':
                    return 'C2';
                    break;
                case 'C3':
                    return 'C3';
                    break;
                case 'C4':
                    return 'C4';
                    break;
                case 'C5':
                    return 'C5';
                    break;
                case '':
                    return '全部';
                    break;
                default:
                    return '未知';
            }
        },
        //车型
        r_CarType(value) {
            switch (value) {
                case 'A1':
                    return 'A1';
                    break;
                case 'A2':
                    return 'A2';
                    break;
                case 'A3':
                    return 'A3';
                    break;
                case 'B1':
                    return 'B1';
                    break;
                case 'B2':
                    return 'B2';
                    break;
                case 'C1':
                    return 'C1';
                    break;
                case 'C2':
                    return 'C2';
                    break;
                case 'C3':
                    return 'C3';
                    break;
                case 'C4':
                    return 'C4';
                    break;
                case 'C5':
                    return 'C5';
                    break;
                case '大车':
                    return '大车';
                    break;
                case '小车':
                    return '小车';
                    break;
                case '':
                    return '全部';
                    break;
                default:
                    return '未知';
            }
        },
        // 客户类型
        r_customerType(value) {
            switch (value) {
                case 'personal':
                    return '个人客户';
                    break;
                case 'publics':
                    return '对公客户';
                    break;
                default:
                    return '未知';
            }
        },
        //审核状态
        r_checkState(value) {
            switch (value) {
                case 'un_check':
                    return '待审核';
                    break;
                case 'reject':
                    return '驳回';
                    break;
                case 'pass':
                    return '通过';
                    break;
                default:
                    return '未知';
            }
        },
        //业务状态
        r_BusinessStatus(value) {
            switch (value) {
                case 'normal':
                    return '正常';
                    break;
                case 'settled':
                    return '已结算';
                    break;
                case 'cancelled':
                    return '已取消';
                    break;
                case 'abnormal':
                    return '异常';
                    break;
                default:
                    return '未知';
            }
        },
        //异常申请
        r_abnormalApplication(value) {
            switch (value) {
                case 'trainingIncomplete':
                    return '训练未完成';
                    break;
                case 'untrained':
                    return '未训练';
                    break;
                case 'hardwareFailure':
                    return '硬件故障';
                    break;
                case 'costMistake':
                    return '订单错误';
                    break;
                case 'other':
                    return '其他';
                    break;
                default:
                    return '未知';
            }
        },
        //星级名称
        r_starClass(value) {
            switch (value) {
                case 0:
                    return '无星';
                    break;
                case -1:
                    return '全部';
                    break;
                case 1:
                    return '一星';
                    break;
                case 2:
                    return '二星';
                    break;
                case 3:
                    return '三星';
                    break;
                case 4:
                    return '四星';
                    break;
                case 5:
                    return '五星';
                    break;
                default:
                    return '未知';
            }
        },
        //星级类型
        r_starType(value) {
            switch (value) {
                case 1:
                    return '车辆';
                    break;
                case 2:
                    return '安全员';
                    break;
                default:
                    return '未知';
            }
        },
        //星级状态
        r_starStatus(value) {
            switch (value) {
                case 0:
                    return '正常';
                    break;
                case 1:
                    return '注销';
                    break;
                default:
                    return '未知';
            }
        },
        //考试车状态
        r_examinationCarStatus(value) {
            switch (value) {
                case 0:
                    return '正常';
                    break;
                case 1:
                    return '注销';
                    break;
                default:
                    return '未知';
            }
        },
        // 车型
        r_vehicleType(value) {
            switch (value) {
                case 'cart':
                    return '大车';
                    break;
                case 'trolley':
                    return '小车';
                    break;
                default:
                    return '未知';
            }
        },
        // 业务数据类型
        r_businessDataType(value) {
            switch (value) {
                case 'ticketFlow':
                    return '小票流水报表';
                    break;
                default:
                    return '未知';
            }
        },
        // 排序
        r_chargeSort(value) {
            switch (value) {
                case 'orderSrc':
                    return '按预约量升序';
                    break;
                case 'orderDesc':
                    return '按预约量降序';
                    break;
                case 'vihicleSrc':
                    return '按车号升序';
                    break;
                case 'vihicleDesc':
                    return '按车号降序';
                    break;
                default:
                    return '未知';
            }
        },
        // 训练场地
        r_trainingGround(value) {
            switch (value) {
                case 'wuheyi':
                    return '五合一';
                    break;
                case 'tangxun':
                    return '塘汛';
                    break;
                default:
                    return '未知';
            }
        },
        // 审核状态
        r_auditStatus(value) {
            switch (value) {
                case '-1':
                    return '全部';
                    break;
                case '0':
                    return '未审核';
                    break;
                case '1':
                    return '审核通过';
                    break;
                case '2':
                    return '审核未通过';
                    break;
                default:
                    return '未知';
            }
        },
        // 学校
        r_school(value) {
            switch (value) {
                case 'shiyan':
                    return '实验';
                    break;
                case 'shengshui':
                    return '圣水';
                    break;
                case 'tongan':
                    return '通安';
                    break;
                default:
                    return '未知';
            }
        },
        // 用户角色
        r_role(value) {
            switch (value) {
                case 'management':
                    return '管理员';
                    break;
                case 'user':
                    return '用户';
                    break;
                default:
                    return '未知';
            }
        },
        // 带多车
        r_canAny(value) {
            switch (value) {
                case 0:
                    return '否';
                    break;
                case 1:
                    return '是';
                    break;
                default:
                    return '未知';
            }
        },
        // 考试车状态
        r_saferStatus(value) {
            switch (value) {
                case 0:
                    return '正常';
                    break;
                case 1:
                    return '注销';
                    break;
                default:
                    return '未知';
            }
        },
        // vip状态
        r_vipStatus(value) {
            switch (value) {
                case 0:
                    return '正常';
                    break;
                case 1:
                    return '暂停';
                    break;
                case 2:
                    return '注销';
                    break;
                default:
                    return '未知';
            }
        },
        // VIP类型
        r_vipType(value) {
            switch (value) {
                case 0:
                    return '一般';
                    break;
                case 1:
                    return '教练';
                    break;
                case 2:
                    return '安全员';
                    break;
                default:
                    return '未知';
            }
        },
        // 费用类型
        r_costType(value) {
            switch (value) {
                case 0:
                    return '租车';
                    break;
                case 1:
                    return '自带车';
                    break;
                default:
                    return '未知';
            }
        },
        // 是否本校
        r_isSch(value) {
            switch (value) {
                case -1:
                    return '全部';
                    break;
                case 0:
                    return '本校';
                    break;
                case 1:
                    return '非本校';
                    break;
                default:
                    return '未知';
            }
        },
        // 业务状态
        r_stageStatus(value) {
            switch (value) {
                case -1:
                    return '全部';
                    break;
                case 0:
                    return '正常';
                    break;
                case 1:
                    return '结算';
                    break;
                case 2:
                    return '异常';
                    break;
                case 3:
                    return '非本校';
                    break;
                default:
                    return '注销';
            }
        },
        // 退费状态
        r_refoundStatus(value) {
            switch (value) {
                case -1:
                    return '全部';
                    break;
                case 0:
                    return '未审';
                    break;
                case 1:
                    return '审核';
                    break;
                case 2:
                    return '驳回';
                    break;
                default:
                    return '注销';
            }
        },
        // 是否查阅
        r_readTag(value) {
            switch (value) {
                case -1:
                    return '全部';
                    break;
                case 0:
                    return '未读';
                    break;
                case 1:
                    return '已读';
                    break;
                default:
                    return '其他';
            }
        },
        // 是否查阅
        r_cType(value) {
            switch (value) {
                case '-1':
                    return '全部';
                    break;
                case '0':
                    return '租车';
                    break;
                case '2':
                    return '优惠券';
                    break;
                case '3':
                    return '团购';
                    break;
                default:
                    return '其他';
            }
        },
    }
}
