
const getters = {
    pageView: state => state.pageLoad, //内容loading+按钮loading属性
    getAdvancedQuery: state => state.StoreDataQuery.queryByAdvanced, //高级查询条件
    userRoles:state=> state.user.roles, //获取用户权限
    currentMenu:state=> state.user.currentMenu, //获取用户当前页面
    getDriverQuery:state=> state.StoreDataQuery.driverSchool, //获取驾校列表
    getStarCostQuery:state=> state.StoreDataQuery.starCost, //获取收费列表
    getStarClass:state=> state.StoreDataQuery.starClass, //获取收费列表
    getNews:state=> state.StoreDataQuery.newsNum, //获取收费列表
    getAbnormalNum:state=> state.StoreDataQuery.AbnormalNum, //获取异常数量
    getFav:state=> state.StoreDataQuery.fav //获取收费列表
  };
  export default getters;