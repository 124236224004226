import axios from "axios";
// 字符串订单号超过18位会被四舍五入，所以安装json-bigint解决这个问题
const JSONbig = require("json-bigint")({ storeAsString: true });
import { getUrls } from "@/utils/index";
import store from "../store/index.js";

// request 拦截器
axios.interceptors.request.use(
  config => {
    // 如果是获取最后一次定位数据，则不触发loading操作
    if(config.url === '/monitor/getLastLocation' ||config.url ==='/overall/getFurtureIntegrationByCount'||config.url ==='/integrationTrain/getLocalTrainAbnormalListCount2'||config.url ==='/vip/getCount'){
      store.dispatch("pageLoad/btnLoading", false);
      store.dispatch("pageLoad/pageLoading", false);
    }else{
    //  设置数据请求loading状态
    store.dispatch("pageLoad/btnLoading", false);
    store.dispatch("pageLoad/pageLoading", false);
    // store.dispatch("pageLoad/btnLoading", true);
    // store.dispatch("pageLoad/pageLoading", true);
    }
    //设置头
    config.headers ={'X-Auth-Token':JSON.parse(sessionStorage.getItem('token')),'X-Auth-SessionID':JSON.parse(sessionStorage.getItem('sessionId'))}
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

/**
 * 封装request请求
 * @param {string} url
 * @param {object} param
 */
export default function request(url, { method, params }) {
  // if(url == "/monitor/earth2Mars"){
  //   axios.defaults.baseURL = "http://10.50.30.112:19001/work";
  // }else{
  //   axios.defaults.baseURL = getUrls(url);
  // }
  axios.defaults.baseURL = getUrls(url);
  axios.defaults.transformResponse = [
    data => {
      // 可以对data做任何操作
      return JSONbig.parse(data);
    }
  ];
  switch (method) {
    case "post":
      return params ? axios.post(url, params) : axios.post(url);
    case "patch":
      return params ? axios.patch(url, params) : axios.patch(url);
    case "put":
      return params ? axios.put(url, params) : axios.put(url);
    case "delete":
      return params ? axios.delete(url, params) : axios.delete(url);
    default:
      return params
        ? axios.get(url, {
            params: {
              ...params
            }
          })
        : axios.get(url);
  }
}

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    // 设置数据成功loading状态
    store.dispatch("pageLoad/btnLoading", false);
    store.dispatch("pageLoad/pageLoading", false);
      if(response.data.result === 110) {//Token失效
          let config = response.config;
          store.dispatch('user/refreshToken').then(() => {
              axios(config);
          }).catch(err =>{
              store.dispatch('user/loginOut');
              alert('token 失效 110');
          });
          // request('/auth/refreshToken',
          //     {
          //         method: "post",
          //         params:{
          //             "userName": sessionStorage.getItem('userName'),
          //             "userId": sessionStorage.getItem('userId')}
          //     }).then(response => {
          //     sessionStorage.setItem('token', JSON.stringify(response.data.token));
          //
          // })
      }
      else if(response.data.result === 101){//验证失败
          store.dispatch('user/loginOut');
          //router.push('/test5');
      }
      else if(response.data.result === 102){ //账号重复登录
          store.dispatch('user/loginOut');
          //router.push('/test5');
      }
      else{
          return response.data ? response.data : response;
      }
    //return response.data ? response.data : response;
  },
  error => {
      //store.dispatch('user/loginOut');
      console.log('error',error);
    setTimeout(()=>{
      store.dispatch("pageLoad/btnLoading", false);
      store.dispatch("pageLoad/pageLoading", false);
    },3000)  
    return Promise.reject(error);
  }
);

