import {
  getAuditList,
} from "@/service/charge/index";
export default {
  //公共打印
  name: "print",
  props: {
    //打印数组
    printList: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      disList: this.printList,
      //编辑页面数据
      businessAudit: {
        list: {
          query: {
            applyDate:this.$moment('2019-05-01').format('YYYY-MM-DD 00:00:00'),
            applyDateEnd: this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
            pageIndex:1,  
            pageSize:1000,    
            unitId:sessionStorage.getItem('unitId'),       
            stageMark:0,
            auditStatus:1,
            userId: sessionStorage.getItem('userId'),
            nameOne:'绵阳市汽车实验驾驶技术学校股份有限公司五合一考场',
            nameTwo:'绵阳市鸿安鼎运汽车租赁服务有限责任公司',
          },
          total: 0,
          results: []
        },
        daterange: [],
      },
    };
  },
  methods: {
    smallToch(n) {
      var fraction = ['角', '分'];
      var digit = [
        '零', '壹', '贰', '叁', '肆',
        '伍', '陆', '柒', '捌', '玖'
      ];
      var unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟']
      ];
      var head = n < 0 ? '欠' : '';
      n = Math.abs(n);
      var s = '';
      for (var i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
      }
      s = s || '整';
      n = Math.floor(n);
      for (var i = 0; i < unit[0].length && n > 0; i++) {
        var p = '';
        for (var j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      return head + s.replace(/(零.)*零元/, '元')
        .replace(/(零.)+/g, '零')
        .replace(/^整$/, '零元整');
    },

    getprintList(list) {
      this.$nextTick(() => {
        this.disList = [...list];
        this.disList.map( item =>{
        if(item.status === 3){
          this.getCheckMan(item.stageMark);
        }
        });
      })
    },

    getCheckMan(stageMark){
      this.businessAudit.list.query.stageMark=stageMark;
      let querys = this.businessAudit.list.query;
       getAuditList(querys).then( res => {
        this.$nextTick(()=>{
         this.businessAudit.list.results=res.data;
        })
      });
    },
  },
  mounted() {
  }
};
