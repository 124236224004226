const state = {
    name: "页面名称",
    btnLoading: false,
    pageLoading: false
};

const mutations = {
    PAGELOADING: (state, payload) => {
        state.pageLoading = payload;
    },
    BTNLOADING: (state, payload) => {
        state.btnLoading = payload;
    }
}

const actions = {
    pageLoading: ({commit}, status) => {
        commit('PAGELOADING',status)
    },
    btnLoading: ({commit}, status) => {
        commit('BTNLOADING',status)
    }
}

const pageLoad = {
    namespaced: true,
    state,
    mutations,
    actions
  };

  export default pageLoad;