<template>
  <div class="editable-cell" @click="edit(value,id,vehId)" :dataId="id">
    <div class="editable-cell-text-wrapper">
      <a-icon  :type="value?value:getVal?getVal:'user-delete'" :style="{'color': (value==='lock')? '#f90':(value==='property-safety')?'#0c0':(value==='user-add')?'#169bd5':'#000'}"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    id: Number,
    vehId: Number
  },
  data() {
    return {
      value:this.text ? this.text :"user-delete",
      isedit: ["lock", "property-safety"],
      editVal: "",
      getVal:''
    };
  },
  methods: {
    edit(value, id, vehId) {
      this.$nextTick(()=>{
      let classN=$('.editable-cell .editable-cell-text-wrapper i')[id].className;
      if (this.isedit.includes(value)||classN==='anticon anticon-lock') {
        return;
      }
      this.editVal = value;
      this.$emit("change", this.value, id, vehId);
      })
    },
    retVal(changeState) {
      this.$nextTick(()=>{
      if (changeState) {
        this.editVal === "user-delete"
          ? (this.value = "user-add")
          : (this.value = "user-delete");
      }
      })
    },
    modify(val) {
      this.getVal=val;
      this.$nextTick(()=>{
        if (val) {
            this.value =val;
        }
      })
    }
  },
  watch: {
    text() {
      this.$nextTick(()=>{
      this.$set(this,"value", this.text);
      })
    },
    value(val, oldVal){
       //普通的watch监听
    },
  },
  mounted() {}
};
</script>
