import Vue from "vue";
//引入状态管理
import Vuex from "vuex";
import pageLoad from "@/store/pageLoad/index.js";
import StoreDataQuery from "@/store/public/index.js";
import user from "./user/index.js"
// import user from   "@/store/user/index.js";
import getters from "./getters.js";
//调用
Vue.use(Vuex);
var store = new Vuex.Store({
    modules: {
        pageLoad,
        StoreDataQuery,
        user
    },
    getters
});

export default store;


