export default {
  name: "content-header",
  props: {
    //header名称
    headerTitle: {
      type: String,
      default: ""
    },
    //是否以另外一种样式展示header，左侧有4个像素的背景填充
    leftSide: {
      type: Boolean,
      default: false
    }
  },
  //公共header
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
