import request from "../../utils/request";
import router from "../../utils/router.js";
const state = {
    roles: [], //用户权限
    currentMenu: ''//用户当前所在菜单
};

const mutations = {
    SETROLES: (state, roles) => {
        state.roles = roles;
    },
    SETCURRENTMENU: (state, cMenu) => {
        state.currentMenu = cMenu;
    }
};

const actions = {
    //设置用户路由
    setUserRoles({ commit }) {
        return new Promise((resolve, reject) => {
            request('/menu/getUserMenu',
                {
                    method: "get",
                    params: { userId: sessionStorage.getItem('userId') }
                }).then(response => {
                    if (response.result == 0) {
                        var obj = {};
                        let usermenudata = response.data.reduce(function (item, next) {
                            obj[next.menuId] ? '' : obj[next.menuId] = true && item.push(next);
                            return item;
                        }, []);
                        sessionStorage.setItem('menus', JSON.stringify(usermenudata));
                        let userRoles = [];
                        // 把具有按钮权限的菜单没有子集的除开
                        let veSId = usermenudata.filter(item => { return item.menuName == 'vehicleStatus' }).length?usermenudata.filter(item => { return item.menuName == 'vehicleStatus' })[0].menuId:''
                        let veStatus = usermenudata.filter(item => { return item.parentId == veSId })
                        let veMId = usermenudata.filter(item => { return item.menuName == 'vehicleMonitoring' }).length?usermenudata.filter(item => { return item.menuName == 'vehicleMonitoring' })[0].menuId:''
                        let veMoni = usermenudata.filter(item => { return item.parentId == veMId })
                        for (let i in usermenudata) {
                            let childrenMenu = [];
                            if (usermenudata[i].menuLevel === 0) {
                                for (let k in usermenudata) {
                                    if (usermenudata[k].parentId === usermenudata[i].menuId) {
                                        if (usermenudata[k].menuId == veSId) {
                                            if (veStatus.length) {
                                                childrenMenu.push({
                                                    meta: { keepAlive: true },
                                                    id: usermenudata[k].menuId,
                                                    name: usermenudata[k].menuText,
                                                    faName: usermenudata[i].menuText,
                                                    iconCls: usermenudata[k].menuIcon,
                                                    fav: usermenudata[k].fav,
                                                    path: '/' + usermenudata[k].menuName,
                                                    component: resolve => require(['../../views/' + usermenudata[i].menuName + '/' + usermenudata[k].menuName + '/index.vue'], resolve)
                                                })
                                            }
                                        } else if (usermenudata[k].menuId == veMId) {
                                            if (veMoni.length) {
                                                childrenMenu.push({
                                                    meta: { keepAlive: true },
                                                    id: usermenudata[k].menuId,
                                                    name: usermenudata[k].menuText,
                                                    faName: usermenudata[i].menuText,
                                                    iconCls: usermenudata[k].menuIcon,
                                                    fav: usermenudata[k].fav,
                                                    path: '/' + usermenudata[k].menuName,
                                                    component: resolve => require(['../../views/' + usermenudata[i].menuName + '/' + usermenudata[k].menuName + '/index.vue'], resolve)
                                                })
                                            }
                                        } else {
                                            childrenMenu.push({
                                                meta: { keepAlive: true },
                                                id: usermenudata[k].menuId,
                                                name: usermenudata[k].menuText,
                                                faName: usermenudata[i].menuText,
                                                iconCls: usermenudata[k].menuIcon,
                                                fav: usermenudata[k].fav,
                                                path: '/' + usermenudata[k].menuName,
                                                component: resolve => require(['../../views/' + usermenudata[i].menuName + '/' + usermenudata[k].menuName + '/index.vue'], resolve)
                                            })
                                        }
                                    }
                                }
                                if (childrenMenu.length > 0) {
                                    userRoles.push({
                                        leaf: true,
                                        name: usermenudata[i].menuText,
                                        iconCls: usermenudata[i].menuIcon,
                                        fav: usermenudata[i].fav,
                                        path: '/Home',
                                        component: resolve => require(['../../views/Home.vue'], resolve),
                                        children: childrenMenu
                                    })
                                }
                            }
                        }
                        commit('SETROLES', userRoles);
                        resolve()
                    }
                    else { reject() }
                })
        }).catch((error) => {
            reject(error);
        })
    },
    // //用户登录
    // userLogin({commit},params) {
    //     return new Promise((resolve, reject) => {
    //         request('/auth/postUserLogin',
    //             {
    //                 method: "post",
    //                 params:params
    //             }).then(res => {
    //             sessionStorage.setItem('userName', res.data.nickName);
    //             sessionStorage.setItem('userId', res.data.userId);
    //             sessionStorage.setItem('userPhone', res.data.userPhone);
    //             sessionStorage.setItem('unitId', res.data.unitId);
    //             sessionStorage.setItem('token', JSON.stringify(res.data.token));
    //             sessionStorage.setItem('sessionId', JSON.stringify(res.data.sessionId));
    //             resolve();
    //         })
    //     }).catch((error) => {
    //         reject(error);
    //     })
    // },
    //退出登录
    loginOut({ commit }) {
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userPhone');
        sessionStorage.removeItem('sessionId');
        sessionStorage.removeItem('unitId');
        sessionStorage.removeItem('sessionId');
        router.push('/login');
        location.reload();
    },
    //刷新token10
    refreshToken() {
        return new Promise((resolve, reject) => {
            request('/auth/refreshToken',
                {
                    method: "post",
                    params: {
                        "userName": sessionStorage.getItem('userName'),
                        "userId": sessionStorage.getItem('userId')
                    }
                }).then(response => {
                    sessionStorage.setItem('token', JSON.stringify(response.data.token));
                    resolve();
                })
        }).catch((error) => {
            reject(error);
        })
    },
    //设置用户当前所在菜单
    setcurrentMenu({ commit }, cMenu) {
        commit('SETCURRENTMENU', cMenu.replace('/', ''));
    },
};

const user = {
    namespaced: true,
    state,
    mutations,
    actions
};

export default user;
