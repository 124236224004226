export default {
  //公共搜索
  name: "drawer",
  props: {
    //是否在多个条件时，展示高级搜索
    drawVis: {
      type: Boolean,
      default: false
    },
    drawVal:{
      type: Array,
      default: []
    }
  },
  data() {
    return {
      visible:this.drawVis,
      value:this.drawVal,
    };
  },
  methods: {
    onClose() {
      this.visible = false;
      this.$emit("change", false);
    },
  }
};
