import request from "../../utils/request";

/**添加收藏
 * @param {object} params
 * @param {string} params.menuName  -菜单路径 必填
 * @param {int} params.userId  -用户id 必填
 */
export function addFav(params) {
  return request("/menu/addFav", {
    method: "post",
    params: params
  });
}

/**取消收藏
 * @param {object} params
 * @param {string} params.menuName  -菜单路径 必填
 * @param {int} params.userId  -用户id 必填
 */
export function cancelFav(params) {
  return request("/menu/cancelFav", {
    method: "post",
    params: params
  });
}

/**获取收藏列表
 * @param {object} params
 * @param {int} params.userId  -用户id 必填
 */
export function getUserFav(params) {
  return request("/menu/getUserFav", {
    method: "get",
    params: params
  });
}

