export default {
  //公共搜索
  name: "drawer",
  data() {
    return {
    };
  },
  methods: {
    onClose() {
      let data1 = [];
      let data2 = [];
      let data3 = [];
      let data4 = [];
      let xAxisData = [];
      var myChart = this.$echarts.init(document.getElementById('brushSelected'));
      for (var i = 0; i < 10; i++) {
        xAxisData.push('Class' + i);
        data1.push((Math.random() * 300).toFixed(2));
        data2.push((-Math.random() * 300).toFixed(2));
        data3.push((Math.random() * 200).toFixed(2));
        data4.push((-Math.random() * 200).toFixed(2));
      }
      var itemStyle = {
        normal: {
          color: () => {
            return '#fff';
          }
        },
        emphasis: {
          barBorderWidth: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        }
      };
      myChart.setOption({
        backgroundColor: 'rgba(220,38,38,0)',
        xAxis: {
          show: false,
          data: xAxisData,
          silent: false,
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {
          show: false,
          inverse: true,
          splitArea: { show: false }
        },
        grid: {
          left: 30,
          top:30,
          right:30,
          bottom:0
        },
        series: [
          {
            name: 'bar',
            type: 'bar',
            stack: 'one',
            itemStyle: itemStyle,
            data: data1
          },
          {
            name: 'bar2',
            type: 'bar',
            stack: 'two',
            itemStyle: itemStyle,
            data: data2
          },
          {
            name: 'bar3',
            type: 'bar',
            stack: 'one',
            itemStyle: itemStyle,
            data: data3
          },
          {
            name: 'bar4',
            type: 'bar',
            stack: 'two',
            itemStyle: itemStyle,
            data: data4
          },
        ]
      });
      this.resize(myChart);
    },
    resize(myChart){
      window.addEventListener('resize', e => { 
        myChart.resize();
      });
    }
  },
  mounted() {
    this.onClose();
  }
};
