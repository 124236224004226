import Vue from 'vue';
import {
    getTrainTrack
} from "@/service/charge/index";
export default {
    name: "vue-map",
    props: {
        queryParam: {
            type: Object,
            default: {}
        },
        checkPhotos: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            cluster: null,//点聚合
            map: {},
            pvmarker: null,//轨迹回放车辆
            driveline: [],//行驶线路
            passedline: [],//已通过线路
            leftline: [],//未通过线路
            cpassed: 0,//当前过线路的坐标数组长度
            tpassed: 0,//全部通过过线路的坐标数组长度
            center: [104.762406, 31.420331],
            vdata: [],//车辆定位数据
            vmarkers: [],//车辆定位
            polyline: [],
            textPosition: [],
            infoWindow: {},//信息窗体
            img: {
                previewImage: '',
                previewVisible: false
            },
            getParams: {}
        };
    },
    computed: {},
    methods: {
        // 地图初始化
        mapInit() {
            this.map = new AMap.Map("maps", {
                resizeEnable: true,
                zoom: 12,
            });
        },
        // 获取地图数据
        getMapData() {
            if (typeof this.getParams.trainBegin === 'number') {
                this.getParams.trainBegin = this.getParams.trainBegin ? this.$moment(this.translateDate(this.getParams.trainBegin)).format('YYYY-MM-DD HH:mm:ss') : '';
                this.getParams.trainEnd = this.getParams.trainEnd ? this.$moment(this.translateDate(this.getParams.trainEnd)).format('YYYY-MM-DD HH:mm:ss') : '';
                if (this.getParams.trainBegin) {
                    if (!this.getParams.trainEnd) {
                        this.getParams.trainEnd = this.$moment(this.getParams.trainBegin).add(this.getParams.costTime, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    }
                }
            }
            const { imei, newImei, trainBegin, trainEnd, costTime } = this.getParams;
            let params = { 'imei': imei, 'newImei': newImei, 'begin': trainBegin, 'end': trainEnd, 'costTime': costTime }
            if ((this.getParams.imei || this.getParams.newImei) && this.getParams.trainBegin) {
                if (this.getParams.imei == this.getParams.newImei) {
                    if (this.$moment(this.getParams.trainEnd).format('DD') - this.$moment(this.getParams.trainBegin).format('DD')) {
                        return;
                    } else {
                        this.getMapMark(params, true)
                    }
                } else {
                    if (this.getParams.imei && this.getParams.newImei) {
                        // 查询两次
                        let twoSouce = [];
                        let query = { 'imei': newImei, 'begin': trainBegin, 'end': trainEnd }
                        this.getMapMark(params, false).then((res) => {
                            twoSouce = [...twoSouce, ...res]
                            this.getMapMark(query, false).then(res => {
                                twoSouce = [...twoSouce, ...res]
                                this.getMapDate(twoSouce);
                            })
                        });

                    } else {
                        let query = { ...params };
                        query.imei = params.imei ? params.imei : params.newImei;
                        this.getMapMark(query, true);
                    }
                }
            }
        },
        // 获取地图坐标点
        async  getMapMark(params, ismodyData) {
            let that = this;
            let response = [];
            await getTrainTrack(params).then(res => {
                if(res.data){
                    this.vdata = [];
                    this.polyline = [];
                    let mapDate = [];
                    mapDate = res.data.reduceRight((all, next) => all.some((atom) => atom['travelSpeed'] == next['travelSpeed'] && atom['locSpeed'] == next['locSpeed'] && atom['direction'] == next['direction'] && atom['alarmFlag'] == next['alarmFlag'] && atom['lat'] == next['lat'] && atom['lng'] == next['lng'] && atom['status'] == next['status']) ? all : [...all, next], []);
                    mapDate = mapDate.map(item => { item.trainTime = that.multiSec(item.time, false) + that.multiSec(that.$moment(item.time).format('mm'), true) + that.getSec(that.$moment(item.time).format('ss')); return item });
                    mapDate = mapDate.sort(this.compareKey());
                    response = mapDate;
                    if (ismodyData) {
                        this.getMapDate(mapDate);
                    }
                }
            })
            return response;
        },
        getMapDate(mapDate) {
            let that = this;
            let trianDate = this.checkPhotos.map(item => {
                if (item) {
                    item.checkTime = that.multiSec(item.photoTime, false) + that.multiSec(that.$moment(item.photoTime).format('mm'), true) + that.getSec(that.$moment(item.photoTime).format('ss'));
                }
                return item
            });
            mapDate = mapDate.map(item => {
                let myShe = trianDate.sort((a, b) => { return Math.abs(a.checkTime - item.trainTime) - Math.abs(b.checkTime - item.trainTime); })[0];
                if (myShe) {
                    item = { ...item, ...{ 'photos': myShe.photosUrl } }
                }
                return item
            });
            mapDate.map((item) => {
                this.vdata.push(new AMap.Marker({
                    position: [item.lng, item.lat],
                    clickable: true,
                    photos: item.photos
                }))
            });
            this.getmarkers();
        },
        multiSec(params, ismin) {
            let timer = params;
            let second = 0;
            let step = 60;
            if (typeof params !== 'string') {
                timer = params.toString();
            }
            if (ismin) {
                step = 60;
            } else {
                timer = timer.split('T')[1].substr(0, 2);
                step = 3600;
            }

            if (timer.length === 2) {
                if (timer.substr(0, 1) === '0') {
                    second = Number(timer.substr(1, 1)) * step;
                } else {
                    second = Number(timer) * step;
                }
            } else {
                second = Number(params) * step;
            }
            return second;
        },
        getSec(params) {
            let timer = params.toString();
            let second = 0;
            if (timer.substr(0, 1) === '0') {
                second = Number(timer.substr(1, 1));
            } else {
                second = Number(timer);
            }
            return second;
        },
        // 数组排序
        compareKey() {
            return function (obj1, obj2) {
                if (obj1['trainTime'] < obj2['trainTime']) {
                    return -1;
                } else if (obj1['trainTime'] === obj2['trainTime']) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },
        //车辆定位数据
        getmarkers() {
            this.vmarkers = [];
            this.textPosition = [];
            this.polyline = [];
            // 创建一个 icon
            var endIcon = new AMap.Icon({
                size: new AMap.Size(25, 34),
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                imageSize: new AMap.Size(135, 40),
                imageOffset: new AMap.Pixel(-95, -3)
            });
            // 创建一个 Icon
            var viaMarker = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(25, 34),
                // 图标的取图地址
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                // 图标所用图片大小
                imageSize: new AMap.Size(135, 40),
                // 图标取图偏移量
                imageOffset: new AMap.Pixel(-54, -3)
            });
            // 创建一个 Icon
            var startIcon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(25, 34),
                // 图标的取图地址
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                // 图标所用图片大小
                imageSize: new AMap.Size(135, 40),
                // 图标取图偏移量
                imageOffset: new AMap.Pixel(-9, -3)
            });
            for (let i in this.vdata) {
                if (i == 0) {
                    this.map.setCenter([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat]);
                    this.vmarkers.push(new AMap.Marker({
                        position: [this.vdata[i].B.position.lng, this.vdata[i].B.position.lat],
                        icon: startIcon,
                        photos: this.vdata[i].B.photos,
                        offset: new AMap.Pixel(-13, -30)
                    }))
                } else if (i == this.vdata.length - 1) {
                    this.vmarkers.push(new AMap.Marker({
                        position: [this.vdata[i].B.position.lng, this.vdata[i].B.position.lat],
                        icon: endIcon,
                        photos: this.vdata[i].B.photos,
                        offset: new AMap.Pixel(-13, -30)
                    }))
                } else {
                    this.vmarkers.push(new AMap.Marker({
                        position: [this.vdata[i].B.position.lng, this.vdata[i].B.position.lat],
                        icon: viaMarker,
                        photos: this.vdata[i].B.photos,
                        offset: new AMap.Pixel(-13, -30)
                    }))
                }
                this.textPosition.push([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat])
                this.polyline.push([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat]);
            }
            this.addText();
            this.addCluster();
            this.addLine();
        },
        // getmarkers() {
        //     this.vmarkers = [];
        //     this.textPosition = [];
        //     this.polyline = [];
        //     for (let i in this.vdata) {
        //         if (i == 0) {
        //             this.map.setCenter([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat]);
        //         }
        //         this.vmarkers.push(new AMap.Marker({
        //             position: [this.vdata[i].B.position.lng, this.vdata[i].B.position.lat],
        //             photos: this.vdata[i].B.photos,
        //             icon: `//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-${Number(i) + 1}.png`,
        //             offset: new AMap.Pixel(-13, -30)
        //         }))
        //         this.textPosition.push([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat])
        //         this.polyline.push([this.vdata[i].B.position.lng, this.vdata[i].B.position.lat]);
        //     }
        //     this.addText();
        //     this.addCluster();
        //     this.addLine();
        // },
        // 添加聚合
        addCluster() {
            this.map.clearMap();
            if (this.cluster) {
                this.cluster.setMap(null);
            }
            var sts = [{
                url: "/static/images/blue.png",
                size: new AMap.Size(32, 32),
                offset: new AMap.Pixel(-16, -16)
            }, {
                url: "/static/images/green.png",
                size: new AMap.Size(32, 32),
                offset: new AMap.Pixel(-16, -16)
            }, {
                url: "/static/images/orange.png",
                size: new AMap.Size(36, 36),
                offset: new AMap.Pixel(-18, -18)
            }, {
                url: "/static/images/red.png",
                size: new AMap.Size(48, 48),
                offset: new AMap.Pixel(-24, -24)
            }, {
                url: "/static/images/darkRed.png",
                size: new AMap.Size(48, 48),
                offset: new AMap.Pixel(-24, -24)
            }];
            this.map.plugin(["AMap.MarkerClusterer"], () => {
                this.cluster = new AMap.MarkerClusterer(
                    this.map,
                    this.vmarkers,
                    {
                        gridSize: 15,
                        styles: sts,
                        minClusterSize: 2,
                        zooms:18
                    }
                );
            });
            this.map.setZoom(18);
        },
        // 添加折线
        addLine() {
            var polyline = new AMap.Polyline({
                path: this.polyline,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#3366bb',   // 线颜色
                strokeWeight: 6           // 线宽
            });

            this.map.add([polyline])
        },
        // 添加文本标记
        addText() {
            this.vmarkers.map(marker => {
                marker.on('click', this.openInfo);
            })
        },
        // 打开信息窗体
        openInfo(marks) {
            console.log('marks:',marks)
            var MyComponent = Vue.extend({
                template: `<div v-on:click="view('${marks.target.B.photos}')" style="background:url(${marks.target.B.photos});width:150px;height:100px; background-repeat:no-repeat; background-position:center center; background-size:cover"></div>`,
                methods: {
                    view: (photos) => {
                        this.viewImg(photos);
                    }
                }
            });
            var component = new MyComponent().$mount();
            // //构建信息窗体中显示的内容
            this.infoWindow = new AMap.InfoWindow({
                content: component.$el,
                offset: new AMap.Pixel(0, -15) //使用默认信息窗体框样式，显示信息内容
            });
            this.infoWindow.open(this.map, [marks.target.B.position.lng, marks.target.B.position.lat]);
        },
        // 图片放大查看
        viewImg(img) {
            this.img.previewImage = img;
            this.img.previewVisible = true;
        },
    },
    created() { },
    mounted() {
        this.getParams = { ...this.queryParam };
        this.mapInit();
        this.getMapData();
    }
};
