export default {
  //公共搜索
  name: "sel",
  props: {
    checked: Object,
    default:{}
  },
  data() {
    return {
      isSel:this.checked.trainType?true:false,
      check:false
    };
  },
  methods: {
      checkChange(value){
        this.isSel=value;
        this.$emit('change',value,this.checked);
      }
  },
  watch: {
      checked: {
           deep: true,
           handler: function (newVal,oldVal){
             this.isSel=newVal.trainType?true:false;
          }
        },
    },
};
