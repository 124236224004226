export default {
  //公共搜索
  name: "Popover",
  props: {
    display:{  
       type: Boolean,
       default:false
    },
    btn:{
      type: Array,
      default:function(){
        return []
    }
    }

  },
  data() {
    return {
      visible:false,
      dis:this.display,
      selectItem:this.selItem,
      btnRole:this.btn
    };
  },
  methods: {
  }
};
