
import {
    allocateVeh,
    getInCompleteWork,
    getTodayWork,
    getVehListInnerDev,
    sendText,
    toggleFuncStart,
    toggleTrainType,
    getAdminCode,
    setAdminCode,
    getLockAndType,
    getLastLocation
} from "@/service/vehicleStatus/index";
import {
    getAuditList,
} from "@/service/charge/index";
import {
    getTeaList,
    getCount,
    getControlLog
} from "@/service/resManagement/index";
export default {
    name: "tree-menu",
    props: {
        itemParams: {
            type: Object,
            default: {}
        },
        vehList: {
            type: Array,
            default: []
        },
        btn:{
            type: Array,
            default:function(){
              return []
          }
          }
    },
    data() {
        return {
            // 模态框参数
            basicForm: this.$form.createForm(this),
            remnant: 50,
            vehicleMonitoring: {
                list: {
                    logresults: [],
                }
            },
            modal: {
                deployvisible: false,
                viewvisible: false,
                screen: false,
                log: false,
                pwd: false,
                sendText: {}
            },
            // 本车当天未完成业务列表
            allocateVeh: {
                total: 0,
                columns: [
                    {
                        title: "操作",
                        dataIndex: "option",
                        width: 75,
                        scopedSlots: {
                            customRender: "option"
                        },
                    },
                    {
                        title: "增加分钟",
                        dataIndex: "addSec",
                        width: 120,
                        align: 'center',
                        scopedSlots: {
                            customRender: "addSec"
                        },
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        width: 100,
                    }, {
                        title: "轮次",
                        dataIndex: "turnCnt",
                        width: 75,
                    }, {
                        title: "科目",
                        dataIndex: "stage",
                        width: 75,
                    }, {
                        title: "联系电话",
                        dataIndex: "phone",
                        width: 100,
                    }, {
                        title: "购买时长",
                        dataIndex: "costTime",
                        width: 100,
                        customRender: item => {
                            return `${item / 60}小时`;
                        },
                    },
                    {
                        title: "已训",
                        dataIndex: "alxun",
                        width: 75,
                    },
                    {
                        title: "未训",
                        dataIndex: "rexun",
                        width: 75,
                    }, {
                        title: "业务状态",
                        dataIndex: "status",
                        width: 100,
                        customRender: item => {
                            return this.$enums.stageStatus[item];
                        },
                    },

                ],
                results: [],
                arr: [],
                item: {},
                newVehNo: '',
                newStage: 0,
                // 调配数组
                changeRrr: [],
                // 未完成数组
                inCompleteArr: [],
                checkTea: false,
                allocate: {
                    newTeaId: 0,
                    newVehId: ''
                }
            },
            // 今日情况查看
            todayWork: {
                total: 0,
                columns: [{
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                }, {
                    title: "业务编号",
                    dataIndex: "stageMark",
                    width: 150,
                }, {
                    title: "轮次",
                    dataIndex: "turnCnt",
                    width: 150,
                }, {
                    title: "科目",
                    dataIndex: "stage",
                    width: 150,
                }, {
                    title: "联系电话",
                    dataIndex: "phone",
                    width: 150,
                },
                {
                    title: "购买时长",
                    dataIndex: "costTime",
                    customRender: item => {
                        return `${item / 60}小时`;
                    },
                    width: 150,
                },
                {
                    title: "业务状态",
                    dataIndex: "status",
                    customRender: item => {
                        return this.$enums.stageStatus[item];
                    },
                    width: 150,
                }
                ],
                results: [],
                item: {}
            },
            // 获取当车密码
            pwd: {
                Code: '',
                params: {}
            },
            // 业务调配
            seltable: {
                columns: [{
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                },
                {
                    title: "业务编号",
                    dataIndex: "stageMark",
                    width: 100,
                }, {
                    title: "轮次",
                    dataIndex: "turnCnt",
                    width: 75,
                }, {
                    title: "科目",
                    dataIndex: "stage",
                    width: 75,
                }, {
                    title: "联系电话",
                    dataIndex: "phone",
                    width: 100,
                }, {
                    title: "购买时长",
                    dataIndex: "costTime",
                    width: 100,
                    customRender: item => {
                        return `${item / 60}小时`;
                    },
                }, {
                    title: "业务状态",
                    dataIndex: "status",
                    width: 100,
                    customRender: item => {
                        return this.$enums.stageStatus[item];
                    },
                },
                ],
                results: [],
                stageParam: ''
            },
            saferManagement: {
                list: {
                    query: {
                        name: '',
                        phone: '',
                        starId: -1,
                        pageIndex: 1,
                        pageSize: 500,
                        unitId: sessionStorage.getItem('unitId'),
                        teaId: '',
                        vehType: '',
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: []
                }
            },
            //控制日志表
            logColumns: [
                {
                    title: "序号",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                },
                {
                    title: "车牌号",
                    dataIndex: "veh"
                }, {
                    title: "车号",
                    dataIndex: "vehNo"
                },
                {
                    title: "命令",
                    dataIndex: "cmd"
                },
                {
                    title: "命令内容",
                    dataIndex: "cmdContent"
                },
                {
                    title: "下发时间",
                    dataIndex: "inTime",
                    customRender: (item => {
                        return this.transForm(item);
                    })
                },
                {
                    title: "操作人",
                    dataIndex: "nickname"
                }],
            carSorce: [],
            saferSorce: [],
            btnRole:this.btn
        };
    },
    computed: {},
    methods: {
        //菜单
        transmenu(item, option) {
            this.querySaferManag();
            this.resetModal();
            // 隐藏小菜单
            this.modal.sendText.devNum = item.unifyCode;
            this.modal.sendText.imei = item.imei;
            if (option === 'deploy') {
                this.modal.deployvisible = true;
                this.getInCompleteWork(item);
            } else if (option === 'view') {
                this.modal.viewvisible = true;
                this.getTodayWork(item)
            } else if (option === 'msg') {
                this.modal.screen = true;
            } else if (option === 'log') {
                this.modal.log = true;
                this.controlLog(item)
            } else if (option === 'pwd') {
                this.pwd.params = { ...item };
                this.modal.pwd = true;
                this.getAdminCode(item);
            }
        },
        // 获取本车当天未完成业务列表
        getInCompleteWork(item) {
            this.allocateVeh.item = item;
            getInCompleteWork(item).then(res => {
                this.allocateVeh.results = res.data.map(item => {
                    return {
                        ...item,
                        ...{
                            'trainAdd': 0
                        }
                    }
                });;
                this.allocateVeh.arr = res.data.map(item => {
                    return {
                        ...item,
                        ...{
                            'trainAdd': 0
                        }
                    }
                });
            }).catch(err => {
                console.log('err', err)
            })
        },
        controlLog(item) {
            let params = { unitId: item.unitId, vehId: item.vehId, begin: this.$moment().subtract('days', 6).format('YYYY-MM-DD HH:mm'), end: this.$moment().format('YYYY-MM-DD HH:mm') }
            getControlLog(params).then((res) => {
                this.vehicleMonitoring.list.logresults = [...res.data];
            })
        },
        // 今日情况查看
        getTodayWork(query) {
            this.todayWork.item = query;
            getTodayWork(query).then(res => {
                this.todayWork.results = res.data;
            }).catch(err => {
                console.log('err', err)
            })
        },
        // 查看此车剩余业务量
        getInComplete() {
            this.allocateVeh.inCompleteArr = [];
            if (!this.allocateVeh.newVehNo.length) {
                this.$message.info('请先输入车号');
                return;
            }
            let arr = this.IsExsitveh();
            if (this.allocateVeh.newStage !== 0) {
                arr = arr.filter(item => {
                    return item.vehNo == this.allocateVeh.newVehNo && item.stage == this.allocateVeh.newStage;
                })
            }
            if (!arr.length) {
                this.allocateVeh.allocate.newVehId = '';
                this.$message.info('此车号不存在，请重新输入');
                return;
            }
            for (let item of arr) {
                this.allocateVeh.allocate.newVehId = item.vehId;
                const query = {
                    'unitId': sessionStorage.getItem('unitId'),
                    'vehId': item.vehId
                }
                getInCompleteWork(query).then(res => {
                    this.allocateVeh.inCompleteArr = [...this.allocateVeh.inCompleteArr, ...res.data];
                }).catch(err => {
                    console.log('err', err)
                })
            }
        },
        // 获取当前车密码
        getAdminCode(item) {
            let query = { devNum: item.unifyCode, imei: item.imei, devId: item.devId }
            getAdminCode(query).then(res => {
                this.pwd.Code = res.data.Code;
            }).catch(err => {
                console.log('err', err)
            })
        },
        // 增加时长
        addSec(costTime, record) {
            this.allocateVeh.arr = this.allocateVeh.arr.map(item => {
                if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
                    return {
                        ...item,
                        ...{
                            'trainAdd': costTime
                        }
                    }
                } else {
                    return item;
                }
            });
            this.allocateVeh.changeRrr = this.allocateVeh.changeRrr.map(item => {
                if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
                    return {
                        ...item,
                        ...{
                            'trainAdd': costTime
                        }
                    }
                } else {
                    return item;
                }
            });
        },
        selbox(e, record) {
            if (e.target.checked) {
                this.allocateVeh.changeRrr.push(record);
            } else {
                this.allocateVeh.changeRrr = this.allocateVeh.changeRrr.filter(item => {
                    return !record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt
                })
            }
        },
        // 是否调配安全员
        changeTea(e) {
            this.allocateVeh.checkTea = e.target.checked;
        },
        // 查询安全员列表
        async querySaferManag() {
            let querys = this.saferManagement.list.query;
            // 判断是否为点击页码查询
            await getCount(querys).then((res) => {
                if (res.data.rowCount) {
                    getTeaList(querys).then((res) => {
                        this.$nextTick(() => {
                            this.saferManagement.list.results = res.data;
                        });
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$nextTick(() => {
                        this.saferManagement.list.results = [];
                        this.saferManagement.list.total = 0;
                    })
                }
            }).catch((err) => {
                console.log('err', err)
            })

        },
        // 安全员
        handleChange(value) {
            this.saferSorce = this.saferManagement.list.results.filter(item => {
                if (item.name.indexOf(value) != -1) {
                    return item;
                }
            }).map(item => {
                return `${item.name}/${item.phone}`
            })
            if (value.indexOf('/') != -1) {
                let newTeaId = this.saferManagement.list.results.filter(item => {
                    if (value.substring(0, value.indexOf('/')) && value.substring(value.indexOf('/') + 1, value.length)) {
                        return item.name === value.substring(0, value.indexOf('/')) && item.phone === value.substring(value.indexOf('/') + 1, value.length)
                    } else if (value.substring(0, value.indexOf('/'))) {
                        return item.name === value.substring(0, value.indexOf('/'))
                    } else {
                        return item.phone === value.substring(value.indexOf('/') + 1, value.length)
                    }

                })
                this.allocateVeh.allocate.newTeaId = newTeaId[0].teaId;
            }
        },
        // 确认调配
        allocateVehOpt() {
            let stageL = this.seltable.stageParam.split('/');
            if (stageL.length <= 1) {
                this.$message.info('请确定科目');
                return;
            }
            if (this.allocateVeh.checkTea) {
                if (!this.allocateVeh.allocate.newTeaId) {
                    this.$message.info('请先选择调配的安全员');
                    return;
                }
            }
            let item = this.IsExsitveh();
            if (!item.length) {
                this.$message.info('此车号不存在，请重新输入');
                this.allocateVeh.allocate.newVehId = '';
                return;
            } else {
                if (stageL[1] === "科目3") {
                    this.allocateVeh.allocate.newVehId = item.filter( ele=>{return ele.stage === 3})[0].vehId;
                } else {
                    this.allocateVeh.allocate.newVehId = item.filter( ele=>{return ele.stage === 2})[0].vehId;
                }
            }
            if (this.allocateVeh.changeRrr.length) {
                this.allocateVeh.changeRrr.map(item => {
                    // 没有返回teaId
                    const query = { ...{ 'cId': item.cId, 'oldVehId': item.vehId, 'trainAdd': item.trainAdd, 'userId': sessionStorage.getItem('userId'), 'oldTeaId': item.teaId ? item.teaId : 0 }, ...this.allocateVeh.allocate }
                    allocateVeh(query).then(res => {
                        if (!res.result) {
                            this.$message.success('调配成功');
                            this.allocateVeh.inCompleteArr = [];
                            this.allocateVeh.changeRrr = [];
                            this.allocateVeh.arr = [];
                            this.allocateVeh.newVehNo = '';
                            this.allocateVeh.allocate.newTeaId = 0;
                            this.allocateVeh.checkTea = false;
                            this.modal.deployvisible = false;
                            this.carSorce = this.list.results.map(item => {
                                return `${item.vehNo}号/科目${item.stage}`
                            })
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log('err', err);
                    })
                })
            } else {
                this.$message.info('请选择需要调配的项，并且输入正确的时间');
            }
        },
        // 只查看异常状态
        error(e) {
            if (e.target.checked) {
                this.allocateVeh.arr = this.allocateVeh.results.filter(item => {
                    return item.status === 2;
                })
            } else {
                this.allocateVeh.arr = this.allocateVeh.results;
            }
        },
        //车号和科目
        carData(value) {
            this.seltable.stageParam = value;
            let arr = [];
            if (!value.trim().length) {
                arr = this.vehList
            } else {
                arr = this.vehList.filter(item => {
                    if (item.vehNo == value) {
                        return item;
                    }
                });
            };
            if (value.indexOf('/') != -1) {
                this.allocateVeh.newStage = value.substr(value.length - 1);
                this.allocateVeh.newVehNo = value.split("号")[0];
            } else {
                this.allocateVeh.newStage = 0;
                this.allocateVeh.newVehNo = value;
            }

            this.carSorce = arr.map(item => {
                return `${item.vehNo}号/科目${item.stage}`
            });
        },
        // 是否存在车号
        IsExsitveh() {
            let arr = [];
            this.vehList.map(item => {
                if (item.vehNo === Number(this.allocateVeh.newVehNo)) {
                    arr.push(item);
                }
            });
            return arr;
        },
        descInput() {
            var txtVal = this.basicForm.getFieldValue('message').length;
            this.remnant = 50 - txtVal;
        },
        // 发送信息
        sendText() {
            this.basicForm.validateFields((err, values) => {
                if (!err) {
                    this.modal.sendText.message = values.message;
                    const query = this.modal.sendText;
                    sendText(query).then(res => {
                        if (!res.result) {
                            this.modal.screen = false;
                            this.remnant = 50;
                            this.handleFormFieldsReset(this.basicForm);
                        }
                    }).catch(err => {
                        console.log('err', err)
                    })
                }
            });
        },
        // 设置当前车密码
        setAdminCode() {
            let query = { ...{ devId: this.pwd.params.devId, devNum: this.pwd.params.unifyCode, imei: this.pwd.params.imei }, ...{ code: this.pwd.Code } }
            setAdminCode(query).then(res => {
                if (!res.result) {
                    this.$message.info("设置密码成功！");
                    this.modal.pwd = false;
                } else {
                    this.$message.info(res.message);
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 设置密码
        setPwd() {
            let str = "",
                arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
                    'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
                    '1', '2', '3', '4', '5', '6', '7', '8', '9',];
            for (let i = 0; i < 8; i++) {
                let pos = Math.round(Math.random() * (arr.length - 1));
                str += arr[pos];
            }
            this.pwd.Code = str;
        },
        // 关闭所有modal
        resetModal() {
            this.modal.deployvisible = false;
            this.modal.viewvisible = false;
            this.modal.screen = false;
            this.modal.log = false;
            this.modal.pwd = false;
        },
        //转为考试
        transType(e, option) {
            if (!e.devId || !e.imei) {
                this.$message.info('该车辆信息不全,请补全信息以后再转换');
                return;
            }
            option === "stu" ? this.transTest(e, 1) : this.transTest(e, 0);
        },
        // 车辆培训/考试状态转换
        transTest(e, val) {
            let query = {
                devId: e.devId,
                devNum: e.unifyCode,
                imei: e.imei,
                trainType: val
            }
            toggleTrainType(query).then(res => {
                if (!res.result) {
                    this.$message.info("状态转换完成");
                    this.$emit('change',false);
                } else {
                    this.$message.info(res.message);
                }
            });
        },
        // 轨迹回放
        polyLine(item){
            this.$emit('change',item);
        }
    },
    created() { },
    mounted() {
    }
};