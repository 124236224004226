export const enums = {
  // 车型
  driverCarType: {
    1: "A1",
    2: "A2",
    3: "C1",
    4: "C2"
  },
  // 科目
  projectItem: {
    0: "全部",
    2: "科目2",
    3: "科目3",
  },
  carNum: {
    one: "1号车",
    two: "2号车",
    three: "3号车",
    four: "4号车",
    five: "5号车",
    six: "6号车",
    seven: "7号车",
    eight: "8号车",
    nine: "9号车",
    ten: "10号车",
    eleven: "11号车",
    twelve: "12号车",
    thirteen: "13号车",
    fourteen: "14号车",
    fifteen: "15号车",
    sixteen:"16号车"
  },
  priceType: {
    examinationCar: "考试车",
    selfVehicle: "自带车",
  },
  isSch: {
    0: "本校",
    1: "非本校",
  },
  // 星级名称
  starClass:{},
  // 星级类型
  starType: {
     1: "车辆",
     2: "安全员",
  },
  // 考试车状态
  examinationCarStatus: {
    0: "正常",
    1: "注销",
  },
    //设备状态
    devStatus:{
    0:"正常",
    1:"注销",
    },
  // 业务状态
  BusinessStatus: {
    normal: "正常",
    settled: "已结算",
    cancelled: "已取消",
    abnormal: "异常",
  },
  checkState: {
    un_check: "待审核",
    reject: "驳回",
    pass: "通过",
  },
  costType:{
    0: "租车",
    1: "自带车",
},
// 业务状态
stageStatus:{
  0: "正常",
  1: "结算",
  2:'异常',
  3:'注销',
  4:'退费'
},
cType:{
  0: "租车",
  2:'优惠券 ',
  3:'团购',
},
 // 异常申请
 abnormalApplication:
  {
    0: "训练未完成",
    1: "未训练",
    2: "硬件故障", 
    3: "订单错误",
    4: "其他",
  },
  auditStatus:{
    0:'未审核',
    1:'审核通过',
    2:'审核未通过',
  },
  fax:{
    '':'全部',
    '-1':'未出票',
    '-2':'已出票',
  }
};